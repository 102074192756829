import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';
import { IInwinkEntityV3QueryOptions } from '../../../../api/front';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { sessionDatasourceV3 } from '../../api/sessions';

export function syncSessions(args: IEventEntitySync): Promise<boolean> {
    let hasChanges = false;
    let hasTemplateUpdate = false;

    if (args.updatedEntityTemplates && args.updatedEntityTemplates.some((t) => t.entityName.toLowerCase() === "session")) {
        hasTemplateUpdate = true;
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {    
            order: [{ by: "validFrom" }],
            selects: {
                $all: true,
                infos: {
                    $all: true
                },
                exhibitors: {
                    id: true
                },
                speakers: {
                    id: true
                },
                eventThemes: {
                    id: true
                },
                journeys: {
                    id: true
                },
                timeslots: {
                    $all: true,
                    room: {
                        $all: true
                    }
                },
                moderators: {
                    moderatorId: true
                },
                sessionPersonQuotas: {
                    $all: true
                }
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    const itemkey = (r, s) => { return r.id === s.id; };
    const itemcallback = (ss: Entities.ISession) => {
        const s = ss;
        hasChanges = true;
        s.eventThemes = s.eventThemes ? s.eventThemes.map((e) => <any>{ id: e.id }) : null;
        s.speakers = s.speakers ? s.speakers.map((sp) => <any>{ id: sp.id }) : null;
        s.exhibitors = s.exhibitors ? s.exhibitors.map((sp) => <any>{ id: sp.id }) : null;
    };

    return syncRecursiveEntity<Entities.ISession>({
        datasource: sessionDatasourceV3(args.eventRequests.apiFront, args.noCache),
        logger: args.logger,        
        data: args.eventData,
        force: hasTemplateUpdate || args.force,
        getBaseOptions: getExpr,
        collectionname: "sessions",
        itemkey: itemkey,
        itemcallback: itemcallback,
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
