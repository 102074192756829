import { logging } from '@inwink/logging';
import { syncSingleEntity } from '@@data/syncutils';
import {
    shouldSync, shouldForceSync
} from '@@event/sync/sync.helpers';
import {
    eventModule
} from '@@routes/appmodules';
import { States } from "@@services/services";
import { syncEventMessages } from '@@event/sync/eventsync/eventmessages';
import { IEventRequests } from '@@services/apiaccessprovider.definition';
import { syncSessions } from '@@event/sync/eventsync/sessions';

const logger = logging.getLogger("Data");

export const syncEventActions = {
    syncEventData(
        eventRequests: IEventRequests,
        eventid: string,
        data: States.IEventDataStore,
        force = false,
        progressCallback: (arg: { progressPercent: number }) => void = null,
        disableFrequentSyncCheck?: boolean
    ) {
        return (dispatch, getState: () => States.IAppState) => {
            return eventModule().then((mod) => {
                return mod.coreSync(
                    logger,
                    eventRequests,
                    eventid,
                    data,
                    force,
                    dispatch,
                    getState,
                    progressCallback,
                    false,
                    disableFrequentSyncCheck
                );
            });
        };
    },

    syncCurrentEvent(force?: boolean) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            return eventModule().then((mod) => {
                return mod.coreSync(
                    logger,
                    state.event.requestManagers,
                    state.event.eventid,
                    state.event.data,
                    force,
                    dispatch,
                    getState
                );
            });
        };
    },

    checkEventData() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.event.data) {
                if (shouldSync(state.event.detail, state.event.data)) {
                    const force = shouldForceSync(state.event.data);
                    syncEventActions.syncCurrentEvent(force)(dispatch, getState).then(null, (err) => {
                        logger.error("error syncing event", err);
                    });
                }
            }
        };
    },

    syncSessions(noCache = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            const event = state.event.detail;
            return syncSessions({
                configuration: event.configuration,
                eventData: state.event.data,
                eventDetail: event,
                eventRequests: state.event.requestManagers,
                trackProgress: null,
                force: false,
                noCache: noCache,
                dispatch,
                getState,
                logger
            }).then((hasUpdates) => {
                if (hasUpdates) {
                    state.event.data.save();
                }
                dispatch({ type: "EVENT_REFRESH" });
            });
        };
    },

    syncEventMessages(force?: boolean) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            return syncSingleEntity({
                requestMgr: state.event.requestManagers.apiFront,
                force,
                dispatch,
                getState,
                entitysyncfunction: syncEventMessages,
                dataStore: state.event.data,
                logger
            });
        };
    }
};
