import type { Entities } from '@inwink/entities/entities';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';
import { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';

export function sessionDatasourceV3(requestManager: IEventRequestManager, noCache?: boolean) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.ISession>(requestManager, "session", "Session", noCache);
    });
}

export function getSessions(requestManager: IEventRequestManager, options?: IInwinkEntityV3QueryOptions, noCache?: boolean) {
    return import("../../../api/front/datasource").then((mod) => {
        const ds = mod.getFrontEntityDataSource<Entities.ISession>(requestManager, "session", "Session", noCache);
        return ds.query(options, []).then((res) => res && res.data);
    });
}

export function getSessionRecommandations(requestManager: IEventRequests, sessionId: string): Promise<any> {
    let url = `session/${sessionId}/recommandations`;
    if (InWinkPreview) url += "?nocache=true";

    return requestManager.apiFront.getEventJson(url);
}

export function markAsAttended(requestManager: IEventRequests, sessionId: string) {
    return requestManager.apiFront.postEventJson<any>("session/markasattended/" + sessionId);
}
