import { logging, ILogger } from '@inwink/logging';
import type { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';
import { getTargetInfoProvider } from '@@services/index';
import { IEventRequests } from '@@services/apiaccessprovider.definition';
import { actions as eventActions } from '../services/eventservice';

const datalogger = logging.getLogger("Data");

let cacheTime = 15;
let cacheFullSyncInMinutes = 24 * 60;
let cacheBgFullSyncInHours = 48;

if (__SERVERSIDE__) {
    cacheTime = process.env.InwinkSyncDelayMinutes ? parseInt(process.env.InwinkSyncDelayMinutes, 10) : 10;
    cacheFullSyncInMinutes = process.env.InwinkFullSyncDelayMinutes ? parseInt(process.env.InwinkFullSyncDelayMinutes, 10) : 120;
    cacheBgFullSyncInHours = process.env.InwinkBgFullSyncDelayHours ? parseInt(process.env.InwinkBgFullSyncDelayHours, 10) : 24;
}

const restrictedTinyUrls = ["[object Object]", ".well-known", "oidc", "assets", "fonts", "lib", "scripts", "null", "undefined"];

export function getEventIdAsync(
    eventRequests: IEventRequests,
    eventtinyurl: string,
    dispatch: (action) => void
): Promise<{eventId: string, authTenantId: string, customerId: string, communityId?: string }> {
    if (!eventtinyurl || restrictedTinyUrls.indexOf(eventtinyurl) >= 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ message: "invalid event key :" + eventtinyurl, status: 404 });
    }

    const provider = getTargetInfoProvider();

    return provider(eventRequests?.apiFront, eventtinyurl).then((eventdetails) => {
        if (dispatch && eventdetails && eventdetails.eventId) {
            eventActions.setEventId(eventdetails.eventId, eventdetails.authTenantId, eventdetails.customerId)(dispatch);
        }
        return eventdetails;
    });
}

export function hasData(eventData: States.IEventDataStore): boolean {
    const eventdetail = eventData.eventDetail.data.find((e) => e.id === eventData.eventId);
    if (eventdetail && eventData.templates.data && eventData.templates.data.length) {
        const lastSync = eventData.lastsyncs.data.find((s) => s.key === "coresync");
        if (lastSync) {
            return true;
        }
    }

    return false;
}

export function hasFullData(eventData: States.IEventDataStore): boolean {
    const eventdetail = eventData.eventDetail.data.find((e) => e.id === eventData.eventId);
    if (eventdetail && eventData.fieldtemplates.data && eventData.fieldtemplates.data.length) {
        const lastSync = eventData.lastsyncs.data.find((s) => s.key === "fullsync");
        if (lastSync) {
            return true;
        }
    }

    return false;
}

export function allowBgFullSync(eventData: States.IEventDataStore, currentlogger?: ILogger): boolean {
    const logger: ILoggerBase = currentlogger || datalogger;
    const lastSync = eventData.lastsyncs.data.find((s) => s.key === "coresync");
    if (lastSync) {
        const dt = new Date(lastSync.date as string);
        const diffInHours = ((new Date() as any) - (dt as any)) / (1000 * 60 * 60);
        logger.debug("allow bgsync ? DATA last core sync was " + diffInHours + " hours ago");
        if (diffInHours < cacheBgFullSyncInHours) {
            return true;
        }
    } else {
        logger.debug("no last core sync");
    }

    return false;
}

export function shouldForceSync(eventData: States.IEventDataStore, currentlogger?: ILogger): boolean {
    const logger: ILoggerBase = currentlogger || datalogger;

    const lastSync = eventData.lastsyncs.data.find((s) => s.key === "fullsync");
    if (lastSync) {
        const dt = new Date(lastSync.date as string);
        const diffInMinutes = ((new Date() as any) - (dt as any)) / (1000 * 60);
        logger.debug("DATA last full sync was " + diffInMinutes + "min ago");
        if (diffInMinutes > cacheFullSyncInMinutes) {
            return true;
        }
    } else {
        logger.debug("no last full sync");
        return true;
    }

    return false;
}

export function shouldSyncDetail(
    eventdetail: Entities.IEventDetail,
    eventData: States.IEventDataStore,
    currentlogger?: ILogger
): { shouldSync: boolean, minutesSinceLastSync: number } {
    const result = {
        shouldSync: false,
        minutesSinceLastSync: 0
    };

    const logger: ILoggerBase = currentlogger || datalogger;

    if (!eventdetail || !eventData) {
        logger.debug("DATA should sync event ? local data does not have event detail");
        result.shouldSync = true;
        return result;
    }

    const lastSync = eventData.lastsyncs.data.find((s) => s.key === "coresync");
    if (!lastSync || !lastSync.date) {
        logger.debug("DATA should sync event ? local data does not have core sync");
        result.shouldSync = true;
        return result;
    }

    const dt = new Date(lastSync.date as string);
    const diff = ((new Date() as any) - (dt as any)) / (1000 * 60);
    result.minutesSinceLastSync = diff;
    if (diff > cacheTime) {
        logger.debug("DATA should sync event ? local data is old " + diff);
        result.shouldSync = true;
    }

    return result;
}

export function shouldSync(
    eventdetail: Entities.IEventDetail, eventData: States.IEventDataStore, currentlogger?: ILogger
): boolean {
    const result = shouldSyncDetail(eventdetail, eventData, currentlogger);
    return result.shouldSync;
}
