import * as moment from 'moment';
import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import { States } from '../../../../services/services';
import { IRequestManager, IEventRequestManager } from '../../../../services/apiaccessprovider.definition';
import { syncEntity, getDate } from '../../../../data/syncutils';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';
import { eventMessageDatasourceV3 } from '../../api/eventmessage';

export function syncEventMessages(requestMgr: IRequestManager, logger: ILoggerBase,
    eventconfig: Entities.IEventDetailConfiguration, eventData: States.IEventDataStore,
    force: boolean, trackProgress?: any): Promise<any> {
    let hasChanges = false;
    const allowMessages = eventconfig?.companion?.eventMessages?.enabled;
    if (!allowMessages) return Promise.resolve();

    return syncEntity<Entities.IEventMessage>({
        logger,
        requestMgr: requestMgr,
        dataStore: eventData,
        force,
        apiCallProvider: (lastsync) => {
            let filters = null;
            if (lastsync) {
                filters = { validFrom: { $gt: getDate(moment(lastsync), true) }};
            }
            const options = {
                filters: filters,
                selects: {
                    $all: true,
                    person: {
                        $all: true
                    },
                    infos: {
                        likes: true
                    }
                },
                page: {
                    index: 0,
                    size: 50
                },
                orders: [{ desc: true, value: "messageDate" }]
            } as IInwinkEntityV3QueryOptions;
            return eventMessageDatasourceV3(
                requestMgr as IEventRequestManager
            ).then((ds) => ds.query(options)).then((res) => res.data.map((_em) => {
                const em = _em;
                em.likes = (em as any).infos.likes;
                return em;
            }));
        },
        // (lastsync) => EventMessageAPI.list(eventData.eventId, 0, 1000, false, lastsync),
        collectionname: "eventmessage",
        itemkey: (r, s) => {
            return r.id === s.id;
        },
        itemcallback: () => {
            hasChanges = true;
        },
        trackProgress }).then(() => hasChanges);
}
